import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Form, Button, InputGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'
import { ListUsers } from '../components/listUsers'


import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'

function DashLocal() {
    // const auth = sessionStorage.getItem('auth')

    // if (auth !== 'true') {
    //   window.location.href = '/'
    // }

    const [userList, setUserList] = useState<any[]>([
        {
            "name": "THIAGUINHO",
            "date": '29/01/2023',
            "time": "22:00",
            "biometricTotal": "190",
            "biometricLeft": "160",
            "active": true,
            step: "Credenciamento",
            "syncStatus": [

            ]
        },
        {
            "name": "NANDO REIS",
            "date": '11/02/2023',
            "time": "19:00",
            "biometricTotal": "342",
            "biometricLeft": "190",
            "active": false,
            step: "Checklist",
            "syncStatus": [

            ]
        },
        {
            "name": "CHICO BUARQUE",
            "date": '02/03/2023',
            "time": "14:00",
            "biometricTotal": "220",
            "biometricLeft": "220",
            "active": true,
            step: "Pronto",
            "syncStatus": [

            ]
        }
    ])

    const [isLoading, setLoading] = useState(false)

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })


    const [show, setShow] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)

    const userSyncProgress = (partialSync: any, totalSync: any) => {
        return Math.round((totalSync / partialSync) * 100);
    }

    const getAllUsers = () => {
        setLoading(true)
        fetch(`${BASE.api.base_url}${BASE.api.find_allusers_with_biometry}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                originCompany: 'da85ecda-9d00-11ed-a8fc-0242ac120002'
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setUserList(response)

                const enabledCount = response.filter(item => item.biometric).length
                setTotalUsers({
                    withBiometry: enabledCount,
                    withoutBiometry: response.length - enabledCount
                })


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    useEffect(() => {
        // getAllUsers()
    }, [])

    return (
        <>
            <Row>
                <Col xl={8}>
                    <Card className="card-access">
                        <Card.Header>
                            <h6 className='text-muted'>Entradas Hoje</h6>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xl={6}>
                                    <div className="row align-items-center justify-content-center card-active">

                                        <h6 className="text-center m-b-10 d-relative">
                                            <span className="text-numbers text-c-blue">9</span>
                                            <i className="feather icon-chevrons-up f-20 m-r-15 text-c-blue" />
                                            <span className="text-muted m-r-5">Entraram</span>
                                        </h6>
                                        {/* <ProgressBar variant="danger" label={'erro'} now={100} /> */}
                                    </div>
                                </Col><Col xl={6}>
                                    <div className="row align-items-center justify-content-center card-active">
                                        <h6 className="text-center m-b-10 d-relative">
                                            <span className="text-numbers text-c-yellow">9</span>
                                            <i className="feather icon-chevrons-down f-20 m-r-15 text-c-yellow" />
                                            <span className="text-muted m-r-5">Saíram</span>
                                        </h6>
                                        {/* <ProgressBar variant="danger" label={'erro'} now={100} /> */}
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Body className="border-bottom">
                            <div className="row d-flex align-items-center">
                                <div className="col-auto">
                                    <i className="feather icon-star f-30 text-c-gray" />
                                </div>
                                <div className="col">
                                    <h3 className="f-w-300">3</h3>
                                    <span className="d-block text-uppercase">Pessoas Totais</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body className="border-bottom">
                            <div className="row d-flex align-items-center">
                                <div className="col-auto">
                                    <i className="feather icon-grid f-30 text-c-yellow" />
                                </div>
                                <div className="col">
                                    <h3 className="f-w-300">19</h3>
                                    <span className="d-block text-uppercase">Biometrias Faltantes</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <AccessLog />
                </Col>
                <Col lg={4}>
                    <FindUser />
                </Col>
            </Row>
        </>
    )
}

export default DashLocal
