import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert
} from 'react-bootstrap'

import { auth } from "./../../config/firebase";

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'


registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'

// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
    return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

const strongPassword = (password: any) => {
    return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        password
    )
}

export function EventCreate() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [hasToken, setHasToken] = useState('')

    const [disableSign, setDisableSign] = useState(false)

    const [eventDue, setEventDue] = useState(new Date())

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })

    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }


    const signOut = async () => {
        try {
            await auth.signOut()
            return true
        } catch (error) {
            return false
        }
    };


    const onSubmit = (data: any) => {
        setDisableSign(true)

        const companyId = sessionStorage.getItem('company')
        const dateFormated = new Date(eventDue)

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                "name": data.name,
                "eventDate": dateFormated,
                "status": "active",
                "isActive": true,
                "company": companyId
            })
        })
            .then((res) => res.json())
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const resetForm = () => {
        setNewUser({
            login: '',
            password: '',
            set: false
        })
        setHasToken('')
        setDisableSign(false)
        reset()
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h5">Criar Evento</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label onClick={() => { signOut() }}>Nome</Form.Label>
                                    <Form.Control
                                        placeholder="Nome do Evento/Atração"
                                        {...register('name', { required: true })}
                                    />
                                    {errors.name && (
                                        <span className="label-error">
                                            Você precisa informar um Nome.
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>Data / hora</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        locale="pt-br"
                                        showTimeInput={true}
                                        timeInputLabel='Hora'
                                        dateFormat="dd/MM/yyyy : HH:mm"
                                        selected={eventDue}
                                        onChange={(date: Date) => setEventDue(date)}
                                    />
                                    {errors.dueDate && (
                                        <span className="label-error">
                                            Você precisa informar uma data válida
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Button
                                            disabled={disableSign}
                                            type="submit"
                                            className="mb-0 w-100"
                                            onClick={() => clearErrors()}
                                        >
                                            Criar Evento
                                        </Button>
                                    </Form.Group>
                                </Col>

                            </Row>
                            {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                        </Form>
                    </Col>
                </Row>

                {newUser.set && (
                    <>
                        <hr />
                        <Row>
                            <Col className="qr-wrapper">
                                <h3>Usuário cadastrado com sucesso!</h3>
                                <p>
                                    Login: <strong>{newUser.login}</strong>
                                    <br />
                                    Senha provisória: <strong>{newUser.password}</strong>
                                </p>
                            </Col>
                        </Row>
                    </>
                )}
            </Card.Body>
        </Card>
    )
}
