import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Form, Button, InputGroup, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'



import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'
import { FindUserByDocument } from '../components/findUserByDocument'

function DashboardFanBase() {


    return (
        <Row>
            <Col lg={8} xl={6}>
                {/* <FindUser /> */}
                <FindUserByDocument />
            </Col>
        </Row>
    )
}

export default DashboardFanBase
