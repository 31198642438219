import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Modal, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import avatar1 from '../../assets/images/user/avatar-1.jpg'

import clubLogo from '../../images/club_logo.svg'
import tombrasil from '../../assets/images/logos-tom-brasil.jpg'
import alma from '../../assets/images/logo-alma.png'



import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreate } from '../components/userCreate'
// import { OrganizationCreate } from './createOrganization'
import { useForm } from 'react-hook-form'

import { ProviderCreate } from './createProvider'
import { ProvidersCategories } from './ProvidersCategories'
import { CoordinatorRow } from '../components/coordinatorRow'

interface Props {
    currentSupplier: any,
}


function EditProviderModal({currentSupplier} : Props) {
    // const auth = sessionStorage.getItem('auth')

    // if (auth !== 'true') {
    //   window.location.href = '/'
    // }

    // const [userList, setUserList] = useState<any[]>([])

    const companyId = sessionStorage.getItem('company')


    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()


    const [disableSign, setDisableSign] = useState(false)

    const [supplierList, setSupplierList] = useState<any[]>([])

    const [isLoading, setLoading] = useState(false)

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })

    const [show, setShow] = useState(false);

    const [currentSupplierId, setCurrentcurrentSupplierId] = useState();



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => {console.log(currentSupplier) ; setShowOrg(true)}
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)


    const inactvate = () => {
        setLoading(true)

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_inactivate}/${currentSupplier.id}`, {
            method: 'POST',
            headers: {
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
               window.location.reload()


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }



    const onSubmit = (data: any) => {
        setDisableSign(true)

        setLoading(true)

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier}/${currentSupplier.id}`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: CONFIG.API_KEY
        }
        })
        .then((resg) => resg.json())
        .then((response) => {
            setLoading(false)
            window.location.reload()
        })
        .catch((error) => {
            setLoading(false)
            console.log(error)
        })

    }


    const resetForm = () => {
        setDisableSign(false)
        reset()
    }


    return (
        <>
            <Link to="#" onClick={() => {  handleShowOrg() }} className="label theme-bg2 text-white f-12">
                Editar
            </Link> 
            <Modal show={showOrg} onHide={handleCloseOrg}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Organização</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    defaultValue={`${currentSupplier?.name}`}
                                    placeholder="Nome"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>CNPJ</Form.Label>
                                <Form.Control
                                    defaultValue={`${currentSupplier?.cnpj}`}
                                    type="text"
                                    placeholder="CNPJ"
                                    {...register('cnpj')}
                                />
                                {errors.cnpj && (
                                    <span className="label-error">
                                        Você precisa informar um CNPJ
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Razão Social</Form.Label>
                                <Form.Control
                                    defaultValue={`${currentSupplier?.trade_name}`}
                                    type="text"
                                    placeholder="Razão Social"
                                    {...register('trade_name')}
                                />
                                {errors.trade_name && (
                                    <span className="label-error">
                                        Você precisa informar uma Razão Social
                                    </span>
                                )}</Col>

                        </Row>
                    

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOrg}>
                        Fechar
                    </Button>
                    <Button variant="primary" type='submit'>
                        Editar
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal>



        </>
    )
}

export default EditProviderModal
