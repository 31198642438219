import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    Table,
    Modal,
} from 'react-bootstrap'

import { Controller, useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import ptBR from 'date-fns/locale/pt-BR'

import { Link } from 'react-router-dom';

import avatar1 from '../../assets/images/user/avatar-1.jpg'
import { CreateCoordinatorLink } from '../Users/createCoordinatorLink'
import { CoordinatorRow } from '../components/coordinatorRow'


export function OrganizationList() {


    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        reset
    } = useForm()

    const [disableSign, setDisableSign] = useState(false)

    const [dashboardNumbers, setDashboardNumbers] = useState({
        biometries: 0,
        suppliers: 0,
        companies: 0
    })


    const [isLoading, setLoading] = useState(false)

    const [companiesList, setCompaniesList] = useState<any[]>([])
    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)

    const [currentCompanyId, setCurrentCompanyId] = useState()

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)

    const excludeCompany = () => {

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.companies}/${currentCompanyId}`, {
            method: 'DELETE',
            headers: {
                'x-api-key': BASE.api.api_key
            }
        })
            .then((res) => res.json())
            .then((response) => {
                handleCloseConfirm()
                getCompanies()
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getCompanies = () => {
        setLoading(true)
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.companies}`, {
            method: 'GET',
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setCompaniesList(response.companies)
                setDashboardNumbers({ ...dashboardNumbers, companies: response.total })

            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const onSubmit = (data: any) => {
        setDisableSign(true)

        // const username = string_to_slug(data.name)

        fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                name: data.name.replace(/^\s+|\s+$/g, ''),
                email: data.email.replace(/^\s+|\s+$/g, ''),
                password: `AM${Math.random().toString(36).slice(-8)}!`,
                role: data.type || 'default'
            })
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const resetForm = () => {
        setDisableSign(false)
        reset()
    }

    useEffect(() => {
        getCompanies()
    }, [])

    return (
        <>
            <Card className="Recent-Users">
                <Card.Header>
                    <Card.Title as="h5">Organizações</Card.Title>
                </Card.Header>
                <Card.Body className="px-0 py-2">
                    <Table responsive hover>
                        <tbody>
                            {companiesList &&
                                companiesList.map((item: any, index) => (
                                    <>
                                        <tr key={index} className="unread">
                                            <td>
                                                {item.slug && (
                                                    <a target={`_blank`} href={`${BASE.onboarding.base_url}/${item.slug}`} className="label theme-bg2 text-white f-12 m-l-15">
                                                        Onboarding Demo
                                                    </a>
                                                )}

                                            </td>
                                            <td>
                                                <img className="rounded-circle" style={{ width: '40px' }} src={
                                                    item.logo
                                                        ? item.logo
                                                        : avatar1
                                                } alt="activity-user" />
                                            </td>
                                            <td>
                                                <h6 className="mb-1">{item.name}</h6>
                                            </td>
                                            <td>
                                                <h6 className="text-muted biometry-status m-t-10">
                                                    status
                                                    <i className={`fa fa-circle text-c-${item.isActive ? 'green' : 'red'} f-12 m-l-15`} />
                                                </h6>
                                            </td>
                                            <td>
                                                <CreateCoordinatorLink supplierId={null} companyId={item.id} />
                                                {/* <Link to="#" onClick={() => { handleShowOrg() }} className="label theme-bg2 text-white f-12">
                                                Editar
                                            </Link> */}
                                                <Link to="#" onClick={() => { setCurrentCompanyId(item.id); handleShowConfirm() }} className="label theme-bg4 text-white f-12 m-l-15">
                                                    X
                                                </Link>
                                            </td>
                                        </tr>
                                        <CoordinatorRow supplierId={null} companyId={item.id} />

                                    </>
                                ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Body>
                    Tem certeza?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirm}>
                        Sair
                    </Button>
                    <Button variant="danger" onClick={excludeCompany}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Criar coordenador</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Telefone"
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Cadastrar Usuário
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col>
                                {disableSign && (
                                    <Form.Group>
                                        <Button
                                            className="mb-0 btn-success w-100"
                                            onClick={() => resetForm()}
                                        >
                                            Cadastrar Outro
                                        </Button>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Criar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

// <tr className='cordinator-row'>
//     <td>
//         <Link to="#" onClick={() => { handleShow() }} className="biometry-status link-coordinator">
//             Editar coordenador
//         </Link>
//     </td>
//     <td colSpan={3}>
//         <h6 className="text-muted text-coordinator m-t-10">
//             {/* {item.coordinator} */}
//         </h6>
//     </td>
//     <td className='text-center'>
//         <Link to="#" onClick={() => { handleShowConfirm() }} className="link-coordinator text-c-red m-l-15">
//             excluir
//         </Link></td>
// </tr>