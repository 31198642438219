import { useEffect, useState } from "react";

export default function useAuth() {

    const [authenticated, setAutenticated] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const token = sessionStorage.getItem('token')

        if (token) {
            setAutenticated(true)
        }
        setLoading(false)
    }, [])

    async function handleLogin() {
        setAutenticated(true)
        history.pushState('', '', '/dashboard')
    }

    async function handleLogout() {
        sessionStorage.removeItem('token')
        setAutenticated(false)
        history.pushState('', '', '/')
    }

    return { authenticated, loading, handleLogin, handleLogout }
}