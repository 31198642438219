import React, { useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Table
} from 'react-bootstrap'

import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'

import './accessLog.sass'

export function AccessLog() {
  const [accessList, setAccessList] = useState([
    {
      name: 'FRaNCISCO GessiNi',
      access_code: 'A00123001X',
      token: '$2a$10$XXVjYUqkFU8kNHH/pAmua.7QCcLuKpzlTY75uAoYZcri8uJioJ74i',
      externalId: '123',
      hikId: 'e4ba336c90284c0db3048f5b07ce4ecb',
      biometric: false,
      gate: 'Matarazzo',
      flow: 'entrance',
      userAvatar: null
    },
    {
      name: 'User Test',
      access_code: 'A00123001X',
      token: '$2a$10$XXVjYUqkFU8kNHH/pAmua.7QCcLuKpzlTY75uAoYZcri8uJioJ74i',
      externalId: '123',
      hikId: 'e4ba336c90284c0db3048f5b07ce4ecb',
      biometric: false,
      gate: 'Palestra',
      flow: 'exit',
      userAvatar: null
    }
  ])

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Acessos | Ao vivo</Card.Title>
      </Card.Header>
      <Card.Body>
        <Table className="striped">
          <tbody className="access-list">
            {accessList &&
              accessList.map((user: any) => (
                <tr key={user.hikiId}>
                  <td className="user-image">
                    <img
                      className="rounded-circle"
                      style={{ width: '40px' }}
                      src={
                        user.userAvatar
                          ? `${BASE.api.base_url}/biometricImages/${user.userAvatar}`
                          : avatar1
                      }
                      alt="activity-user"
                    />
                  </td>
                  <td className="access-detail">
                    <h6 className="mb-1">{user.name}</h6>
                    <p className="m-0">
                      Mat. {user.externalId} | {user.gate}
                    </p>
                  </td>
                  <td>
                    {user.flow === 'entrance' ? (
                      <i className="fa fa-caret-up f-22 m-r-10 text-c-green" />
                    ) : (
                      <i className="fa fa-caret-down f-22 m-r-10 text-c-red" />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}
