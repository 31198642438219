import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Form, Button, InputGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'



import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'

import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

registerLocale('pt-br', ptBR)
import 'react-datepicker/dist/react-datepicker.css'

function DashProvider() {
    // const auth = sessionStorage.getItem('auth')

    // if (auth !== 'true') {
    //   window.location.href = '/'
    // }

    const supplierId = sessionStorage.getItem('company')

    const [isLoading, setLoading] = useState(false)

    const [showOlder, setShowOlder] = useState(false)

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0,
        total: 0
    })

    const [eventDate, setEventDate] = useState<any>()

    const dateConvert = (date: any) => {
        const eventDate = new Date(date)

        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }

        return `${eventDate.toLocaleDateString(
            'pt-br'
        )} ${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`
    }

    const [show, setShow] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)

    const userSyncProgress = (partialSync: any, totalSync: any) => {
        return Math.round((totalSync / partialSync) * 100);
    }


    const [eventList, setEventList] = useState<any[]>([])
    const [nextEventsCount, setNextEventCount] = useState(0)
    const [suppliersCount, setSuppliersCount] = useState(0)

    const getAllEvents = () => {
        setLoading(true)

        const companyId = sessionStorage.getItem('company')

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_supplier_list}/${companyId}/${showOlder}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setEventList(response)
                setNextEventCount(response.length || 0)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues
    } = useForm()

    const searchAllEvents = (data: any) => {

        if (eventDate) {
            data.eventDate = eventDate.toISOString().split('T')[0]
        }

        const companyId = sessionStorage.getItem('company')

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_supplier_list}/${companyId}/${showOlder}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify(data)
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setEventList(response)
                setNextEventCount(response.length || 0)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const getAllUsers = () => {
        setLoading(true)


        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_employees}/${supplierId}/true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)

                const enabledCount = response.biometries
                setTotalUsers({
                    withBiometry: enabledCount,
                    withoutBiometry: response.users.length - enabledCount,
                    total: response.users.length
                })


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }
    useEffect(() => {
        getAllEvents()
        getAllUsers()
    }, [])

    return (
        <>
            <Row>
                <Col md={4}>
                    <Card>
                        <Card.Body className="border-bottom">
                            <div className="row d-flex align-items-center">
                                <div className="col-auto">
                                    <i className="feather icon-star f-30 text-c-gray" />
                                </div>
                                <div className="col">
                                    <h3 className="f-w-300">{nextEventsCount}</h3>
                                    <span className="d-block text-uppercase">Eventos</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Body className="border-bottom">
                            <div className="row d-flex align-items-center">
                                <div className="col-auto">
                                    <i className="feather icon-alert-circle f-30 text-c-yellow" />
                                </div>
                                <div className="col">
                                    <h3 className="f-w-300">{totalUsers.withoutBiometry}</h3>
                                    <span className="d-block text-uppercase">Biometrias Incompletas</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Body className="border-bottom">
                            <div className="row d-flex align-items-center">
                                <div className="col-auto">
                                    <i className="feather icon-users f-30 text-c-green" />
                                </div>
                                <div className="col">
                                    <h3 className="f-w-300">{totalUsers.total}</h3>
                                    <span className="d-block text-uppercase">Pessoas Cadastradas</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col sm={12}>
                    <Card className="Recent-Users">
                        <Card.Header>
                            <Form onSubmit={handleSubmit(searchAllEvents)}>
                                <Row className="align-items-center">
                                    <Col sm={12} md={1}>
                                        <Card.Title as="h5">Eventos</Card.Title>
                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                                            Evento
                                        </Form.Label>
                                        <Form.Control id="inlineFormInputName" placeholder="Nome do evento" {...register('eventName')} />
                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Form.Label htmlFor="inlineFormInputGroupUsername" visuallyHidden>
                                            Data
                                        </Form.Label>
                                        <DatePicker
                                            className="form-control"
                                            locale="pt-br"
                                            dateFormat="dd/MM/yyyy"
                                            selected={eventDate}
                                            onChange={(date: Date) => setEventDate(date)}
                                        />
                                    </Col>
                                    <Col sm={12} md={2}>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Mostrar anteriores?"
                                            onChange={() => {
                                                setShowOlder(!showOlder)
                                            }}
                                        />
                                    </Col>


                                    <Col sm={12} md={3} lg={2} >
                                        <Button className='w-100' type="submit">Pesquisar</Button>
                                    </Col>
                                </Row>
                            </Form>

                        </Card.Header>
                        <Card.Body className="px-0 py-2">
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Data - Hora</th>
                                        <th>Evento</th>
                                        {/* <th>Biometrias</th> */}
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {eventList &&
                                        eventList.map((item: any, index) => (

                                            <tr key={index} className="unread">
                                                <td>
                                                    <h6 className="text-muted biometry-status m-t-10">
                                                        status
                                                        <i className={`fa fa-circle text-c-${item.isActive ? 'green' : 'red'} f-12 m-l-15`} />
                                                    </h6>
                                                </td>
                                                <td>
                                                    <h6 className="mb-1">{dateConvert(item.eventDate)}</h6>
                                                </td>
                                                <td>
                                                    <h6 className="mb-1">{item.name}</h6>
                                                </td>
                                                {/* <td>
                                        <h6 className="text-muted biometry-status m-t-10">
                                            fase: [ {item.status} ]
                                        </h6></td> */}
                                                {/* <td>

                                                    {item.biometricTotal && userSyncProgress(item.biometricTotal, item.biometricLeft) < 100 ?

                                                        (userSyncProgress(item.biometricTotal, item.biometricLeft) < 0) ?
                                                            <ProgressBar variant="danger" label={'erro'} now={100} /> :
                                                            (<ProgressBar animated label={`${userSyncProgress(item.biometricTotal, item.biometricLeft)}%`} now={userSyncProgress(item.biometricTotal, item.biometricLeft)} />) :
                                                        userSyncProgress(item.biometricTotal, item.biometricLeft) > 0 ?
                                                            (
                                                                <ProgressBar variant="success" label={'completa'} now={100} />
                                                            ) : ''
                                                    }
                                                </td> */}

                                                <td>
                                                    <Link to={`/provider/events/${item.id}/${supplierId}`} className="label theme-bg text-white f-12 m-l-15">
                                                        Pessoas
                                                    </Link>
                                                </td>
                                            </tr>

                                        ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default DashProvider
