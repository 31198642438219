import React, { createContext } from 'react'
import PropTypes from 'prop-types';
import { Navigate } from "react-router-dom";

import useAuth from './hooks/useAuth'

const Context = createContext({
    authenticated: false,
    loading: true,
    handleLogin: {},
    handleLogout: {}
})


AuthProvider.propTypes = {
    children: PropTypes.object
};


function AuthProvider<CompProps>({ children }) {

    const { authenticated, loading, handleLogin, handleLogout } = useAuth()

    console.log('paitaon?', authenticated)
    console.log('lodado?', loading)
    if (loading) {
        return <p>oie</p>
    }

    if (!authenticated) {
        return <Navigate to="/" />;
    }
    return (
        <Context.Provider value={{ authenticated, loading, handleLogin, handleLogout }}>
            {children}
        </Context.Provider >
    )
}

export { Context, AuthProvider }